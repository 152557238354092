import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TabelaPicking from './TabelaPicking';
import { validateToken } from '../Utils/Cookie'
import { BsInfoCircle } from "react-icons/bs";
import { parseNumberFormat } from '../Utils/Format';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalReservados(props) {
  const [open, setOpen] = React.useState(false);
  const [offline, setOffline] = React.useState(false);



  const handleClickOpen = () => {
    const { permissions } = props
    if (!validateToken()) {
      const empresa = localStorage.getItem("codigodaempresa")
      localStorage.clear();
      localStorage.setItem("codigodaempresa", empresa);
      props.history.push("/login?logout=true")
    }

    if (permissions && permissions.length > 0) {
      const isRca = permissions.some(permission => permission.displayName === 'Espelho - RCA')
      if (isRca) {
        props.handleSnackbar({ message: 'Acesso bloqueado', variant: 'error', duration: 2000 })
        return
      }
    }

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {props.isDesktop ? (

        <Tooltip placement="bottom-start" title={'Reserva Exclusiva'}>
          <div style={{ marginBottom: 15 }}>
            <span style={{ marginRight: '5px' }}>
              <a href={'#'} onClick={handleClickOpen}>
                {props.titulo || 'Campo não localizado.'}
              </a>
              :
              {' '}
              {parseNumberFormat(props.totPicking)}
            </span>
            <BsInfoCircle size={16} style={{ color: 'blue' }} />
          </div>
        </Tooltip>
      )
        :
        (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '15px',
              marginBottom: '15px'
            }}
            onClick={() => {
              props.handleSnackbar({
                message: 'Reserva Exclusiva',
                variant: 'info',
                duration: 100000
              })
            }}
          >
            <span style={{ marginRight: '5px' }}>
              <a href={'#'} onClick={handleClickOpen}>
                {props.titulo || 'Campo não localizado.'}
              </a>
              :
              {' '}
              {parseNumberFormat(props.totPicking)}
            </span>
            <BsInfoCircle size={16} style={{ color: 'blue' }} />
          </div>
        )}
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="xl">
        {offline && (
          <Typography
            style={{
              backgroundColor: '#222',
              color: '#FFF',
              padding: 20,
              width: '100%',
              textAlign: 'center'
            }}>
            Você está visualizando uma consulta offline.
          </Typography>
        )}
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Consulta {props.titulo || 'Campo não localizado.'}
        </DialogTitle>
        <DialogContent dividers>
          <TabelaPicking pedidos={props.pedidos} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
