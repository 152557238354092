import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  height: fit-content;
  padding: 10px;
  background: rgba(0,0,0,.9);
  z-index: 90;
  color: #FFF;
  font-size: 14px;
  font-weight: bold;

  span {
    font-size: 16px;
    color: darkcyan;
  }
`
