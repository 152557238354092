import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TabelaBid from './TabelaBid';
import Loading from './Loading';
import { validateToken } from '../Utils/Cookie'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalBid({
  codPro,
  codEmp,
  permissions,
  qtd,
  handleSnackbar
}) {
  const [open, setOpen] = React.useState(false);
  const [pedidos, setPedidos] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const handleClickOpen = () => {
    if (!validateToken()) {
      const empresa = localStorage.getItem("codigodaempresa")
      localStorage.clear();
      localStorage.setItem("codigodaempresa", empresa);
    }

    if(permissions && permissions.length > 0) {
      const accessBid = permissions.some(permission => {
       return permission.displayName === 'Espelho - Admin'
        || permission.displayName === 'Espelho - Importação'
        || permission.displayName === 'Espelho - Diretoria'
      })
      if (!accessBid){
        handleSnackbar({message: 'Acesso bloqueado', variant: 'error', duration: 2000})
        return
      }
    }

    setOpen(true);
    setPedidos([]);
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_NARWAL_TEMPORARIA}/lista-bid/${codPro}/${codEmp}`, {
      headers: { method: 'GET', 'Content-Type': 'application/json' },
    }).then((res) => res.json().then((json) => {
      json.forEach((item, i) => {
        item.id = i + 1;
      });
      json.map((item) => {
        const date = item.DataBid.toString().substring(0, 10)
        const [year, month, day] = date.split('-');
        item.DataBid = `${day}/${month}/${year}`;
        if (!item.Status) {
          item.Status = 'Em Cotação'
        }
      })
      setLoading(false);
      setPedidos(json);
    }));
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <a href={'#'} onClick={handleClickOpen}>
        Total em Cotação
      </a>
      : {qtd}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Consulta Bid
        </DialogTitle>
        <DialogContent dividers>
          {loading && <Loading />}
          <TabelaBid permissions={permissions} pedidos={pedidos} />
        </DialogContent>
        <DialogActions>
          <span>{`Total bid: ${qtd}`}</span>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
