import React, { useEffect, useState } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Button,
} from "@material-ui/core";
import axios from "axios";
import { isDesktop } from "react-device-detect";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import FadeLoader from "react-spinners/FadeLoader";
import { useHistory } from "react-router-dom";
import { parseParamToObject, validateToken } from "../Utils/Cookie";
import Snackbar from "./CustomSnackbar";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "absolute",
      top: 0,
      left: 0,
      justifyContent: "center",
    },
    spinner: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10%",
      maxWidth: "200px",
    },
    divImage: {
      width: "65%",
      height: "100vh",
      justifyContent: "center",
      "& > img": {
        width: "auto",
        height: "auto",
        maxHeight: "100vh",
      },
    },
    divImageError: {
      width: '65%',
      height: '100vh',
      justifyContent: 'center',
      backgroundColor: '#1A3661',
      '& > img': {
        width: 'auto',
        height: '50%',
        maxHeight: '100vh'
      }
    },
    content: {
      display: "flex",
      flexDirection: "column",
      width: isDesktop ? "35%" : "100%",
      height: "100vh",
      backgroundColor: "white",
      fontFamily: 'Montserrat',
    },
    preview: {
      width: "100%",
      height: "100%",
    },
    boxTitle: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      marginTop: "5%",
    },
    boxForm: {
      display: "flex",
      marginTop: "20%",
      justifyContent: "center",
      "& > .titleForm": {
        flexDirection: "column",
      },
    },
    selectEmpresa: {
      width: isDesktop ? "75%" : "75%",
      borderRadius: "10px",
      marginTop: "10px",
      marginBottom: "10px",
      "& > div": {
        display: "flex",
        alignItems: "flex-start",
      },
    },
    spanBemVindo: {
      fontSize: "20px",
      color: "#757575",
      lineHeight: "24.38px",
    },
    spanTitleBemVindo: {
      fontSize: "32px",
      color: '#1A3661',
      fontWeight: 700,
      lineHeight: "30.01px",
    },
    spanAcesseConta: {
      color: 'black',
      fontWeight: 600,
      lineHeight: '37.88px',
      fontSize: '31.08px',
    },
    spanSelecione: {
      color: '#1A3661',
      fontWeight: 500,
      fontSize: '14.75px',
      lineHeight: '17.98x',
    },
    spanProblemas: {
      fontWeight: 500,
      fontSize: "13px",
      lineHeight: "15.85px",
      "& > a": {
        marginLeft: "5px",
        fontWeight: 700,
      },
    },
    button: {
      marginTop: "12%",
      display: "flex",
      flexDirection: "column",
      "& > button": {
        backgroundColor: "#757575",
        borderRadius: "35px",
        color: "white",
        fontWeight: 500,
        width: "360px",
        height: "46px",
      },
    },
  })
);

const Login = () => {
  const lastCodEmp = localStorage.getItem("codigodaempresa")
    ? Number(localStorage.getItem("codigodaempresa"))
    : 1;
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUrl, setIsLoadingUrl] = useState(true)
  const [urlBackground, setUrlBackground] = useState("");
  const [empresa, setEmpresa] = useState(lastCodEmp);
  const [isErrorUrlImage, setIsErrorImage] = useState(false);
  const [isErrorSession, setIsErrorSession] = useState(false);
  const [messageSnack, setMessageSnack] = useState("");
  const [variantSnack, setVariantSnack] = useState("");
  const [openSnack, setOpenSnack] = useState(false);

  const redirectTo = process.env.REACT_APP_FRONT_URL || "";
  localStorage.setItem("redirectTo", redirectTo);

  const handleSnackbar = (message, variant) => {
    setMessageSnack(message)
    setVariantSnack(variant)
    setOpenSnack(true)
  }

  const handleLogout = () => {
    const loginApi = process.env.REACT_APP_LOGIN_API;
    const frontUrl = process.env.REACT_APP_FRONT_URL;
    const codigodaempresa = localStorage.getItem("codigodaempresa");

    localStorage.clear();
    localStorage.setItem("codigodaempresa", codigodaempresa);
    window.location.href = `${loginApi}/signout?redirectTo=${frontUrl}/login?logout=true`;
  };

  const handleLogin = () => {
    const loginApi = process.env.REACT_APP_LOGIN_API;
    const frontUrl = process.env.REACT_APP_FRONT_URL;

    localStorage.setItem("codigodaempresa", empresa);
    window.location.href = `${loginApi}/signin?redirectTo=${frontUrl}/login`;
  };

  const registerLogin = async (
    name,
    email,
    expiresIn,
    accessToken,
    codEmp,
    azureId
  ) => {
    try {
      setIsLoading(true);
      const credentials = {
        empresa,
        name,
        email,
        expiresIn,
        accessToken,
        azureId,
      };
      const url = `${process.env.REACT_APP_API}/registerLogin`;
      const { data } = await axios.post(url, credentials);

      localStorage.setItem("token", data.token);
      localStorage.setItem("schema", data.schema);
      localStorage.setItem("codigodaempresa", empresa);
      localStorage.setItem('azureId', azureId)
      history.push("/");
    } catch (error) {
      handleSnackbar(
        error?.response?.data.message || "Ocorreu uma falha interna servidor.",
        "error"
      );
      localStorage.clear();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getUrlBackgroundImage = async () => {
      setIsLoadingUrl(true)
      try {
        const urlResponse = await axios.get(
          `${process.env.REACT_APP_LOGIN_API}/image-background`
        );
        setUrlBackground(urlResponse.data.url);
      } catch (error) {
        console.log(error, "erro image");
        setIsErrorImage(true);
      } finally {
        setIsLoadingUrl(false)
      }
    };

    const initSystem = async () => {
      setIsErrorSession(false);
      if (validateToken()) {
        history.push("/");
        return;
      }
      const search = history.location.search;
      const params = parseParamToObject(search);
      const {
        email,
        name,
        logout,
        accessToken,
        expires,
        expiresIn,
        azureId,
        error,
      } = params;

      if (error) {
        handleSnackbar(error, "error");
        setIsErrorSession(true);
        return;
      }

      if (logout) {
        localStorage.clear();
        localStorage.setItem("codigodaempresa", String(lastCodEmp));

        return;
      }

      if (accessToken && expires) {
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("expiresToken", expires);
        localStorage.setItem("nmRep", name);
        await registerLogin(
          name,
          email,
          expiresIn,
          accessToken,
          lastCodEmp,
          azureId
        );
        return;
      }

      if (!validateToken()) {
        history.push("/");
      }
    };

    getUrlBackgroundImage();
    initSystem();
  }, []);

  const getBackground = () => {
    if (isErrorUrlImage) {
      // return <BrokenImageIcon color='primary' style={{ fontSize: '70px' }} />
      return (
        <img
          src={process.env.PUBLIC_URL + '/grupo_rojemac_branco.png'}
          alt={'imagem de fundo do sistema portal de venda'}
          height={'50%'}
        />
      )
    }

    if (isLoadingUrl) {
      return (
        <Grid container alignItems='center' className={classes.spinner}>
          <FadeLoader color='#1A3661' />
        </Grid>
      )
    }

    return (
      <img
        src={urlBackground}
        alt={'imagem de fundo do sistema portal de venda'}
      />
    )
  }

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Snackbar
        message={messageSnack}
        variant={variantSnack}
        open={openSnack}
        onClose={() => setOpenSnack(false)}
      />
      {isDesktop && (
        <Grid container alignItems='center' className={isErrorUrlImage ? classes.divImageError : classes.divImage}>
          {getBackground()}
        </Grid>
      )}
      <Grid className={classes.content}>
        <Grid className={classes.boxTitle}>
          <span className={classes.spanBemVindo}>Olá! Bem-vindo ao</span>
          <span className={classes.spanTitleBemVindo}>Espelho</span>
        </Grid>
        <Grid container alignItems="flex-start" className={classes.boxForm}>
          <Grid container alignItems="center" className="titleForm">
            <span className={classes.spanAcesseConta}>Acesse sua conta</span>
            <span className={classes.spanSelecione}>
              Selecione a empresa que deseja entrar
            </span>
          </Grid>
          <Grid container alignItems="center" className="titleForm">
            <Select
              value={empresa}
              variant="outlined"
              onChange={(e) => setEmpresa(Number(e.target.value))}
              className={classes.selectEmpresa}
            >
              <MenuItem key={"empresa-1"} value={1}>
                Wolff
              </MenuItem>
              <MenuItem key={"empresa-2"} value={2}>
                Lyor
              </MenuItem>
            </Select>
            <Grid style={{ marginLeft: "35%" }}>
              <span className={classes.spanProblemas}>
                Problemas com login?
                <a
                  href="https://portalcsc.rojemac.com.br/index.php?noAUTO=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  Clique aqui
                </a>
              </span>
            </Grid>
          </Grid>
          <Grid className={classes.button}>
            {isErrorSession ? (
              <Button variant="contained" onClick={handleLogout}>
                DESCONECTAR
              </Button>
            ) : (
              <Button variant="contained" onClick={handleLogin}>
                LOGIN
              </Button>
            )}
          </Grid>
          {isLoading && (
            <Grid container alignItems="center" className={classes.spinner}>
              <FadeLoader color="#1A3661" />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
