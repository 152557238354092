import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { validateToken } from "../Utils/Cookie";
import TabelaFob from "./TabelaFob";
import axios from "axios";

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  rootDialog: {
    width: '50%'
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: 'space-between',
    display: 'flex'
  },
}))(MuiDialogActions);

export default function ModalTabelaFob({
  codPro,
  history,
  permissions,
  handleSnackbar
}) {
  const [open, setOpen] = React.useState(false);
  const [fobs, setFobs] = React.useState([])
  const [loader, setLoader] = React.useState(false)

  const syncTabelaFobs = async (codEmp) => {
    try {
      setLoader(true)
      setFobs([])
      const endpoint_api = process.env.REACT_APP_API;
      const { data } = await axios.get(`${endpoint_api}/get-tabela-fob`, {
        params: {
          codPro,
          codEmp
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      if(data.fobs.length === 0) {
        handleSnackbar({ message: 'Sem dados para exibição', variant: 'warning', duration: 2000 })
        setOpen(false)
      }

      setFobs(data.fobs)
    } catch (error) {
      const messageError = error?.response?.data?.message || error.message
      handleSnackbar({ message: messageError, variant: 'error', duration: 2000 })
    } finally {
      setLoader(false)
    }
  }

  const handleClickOpen = () => {
    const empresa = localStorage.getItem("codigodaempresa")
    if(!validateToken()) {
      localStorage.clear();
      localStorage.setItem("codigodaempresa", empresa);
      history.push("/login?logout=true")
    }

    if(permissions && permissions.length > 0) {
      const isImportacao = permissions.some(permission =>
        permission.displayName === 'Espelho - Importação'
        || permission.displayName === 'Espelho - Diretoria'
        || permission.displayName === 'TI - Desenvolvimento'
      )
      if (!isImportacao){
        handleSnackbar({ message: 'Acesso bloqueado', variant: 'error', duration: 2000 })
        return
      }
    }
    if(!codPro){
      handleSnackbar({ message: 'Produto não informado', variant: 'error', duration: 2000 })
      return
    }
    setOpen(true);
    syncTabelaFobs(empresa)
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <a href={'#'} onClick={handleClickOpen}>Tabela FOB</a>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xl"
        fullWidth
        // className={'dialog-fabricante'}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Consulta Tabela FOB
        </DialogTitle>
        <DialogContent dividers>
          <TabelaFob setFobs= {setFobs} fobs={fobs} loader={loader} permissions={permissions} />
        </DialogContent>
        <DialogActions>
          {/* <span>Tabela FOB</span> */}
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
