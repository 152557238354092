import React from "react";
import "./App.css";
import { Route, BrowserRouter } from "react-router-dom";
import dotenv from "dotenv";
import Espelho from "./Components";
import Login from "./Components/Login";
import "typeface-roboto";
import {
  createTheme,
  ThemeProvider,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";

function App() {
  dotenv.config({
    path: process.env.NODE_ENV === "production" ? ".env" : ".env.development",
  });
  const theme = createTheme({},ptBR);
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <header />
        <MuiThemeProvider theme={theme}>
          <BrowserRouter>
            <Route path="/login" exact component={Login} />
            <Route path="/" exact component={Espelho} />
            <Route path="/produto/:codigoProduto" component={Espelho} />
          </BrowserRouter>
        </MuiThemeProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
