import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { parseNumberFormat } from "../Utils/Format";

function dayInMonthComparator(v1, v2) {
  v1 = `${v1.split("/")[2]}-${v1.split("/")[1]}-${v1.split("/")[0]}`;
  v2 = `${v2.split("/")[2]}-${v2.split("/")[1]}-${v2.split("/")[0]}`;
  return new Date(v1) - new Date(v2);
}

const columns = [
  { field: "id", headerName: "#", flex: 1, minWidth: 200, hide: true },
  {
    field: "numNfv",
    headerName: "Nota Fiscal",
    type: "number",
    minWidth: 200,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "qtdPro",
    headerName: "Qtde",
    type: "number",
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    flex: 1,
    render: (params) => parseNumberFormat(params.value)
  },
  {
    field: "datSai",
    headerName: "Data Saída",
    type: "date",
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => params.row.datSai,
    sortComparator: dayInMonthComparator,
  },
  {
    field: "datEnt",
    headerName: "Data Entrada",
    type: "date",
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (params) => params.row.datEnt,
    sortComparator: dayInMonthComparator,
  },
];

export default function TabelaFabricando({ fabricados }) {
  console.log(fabricados, 'fabricados')
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={fabricados}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
      />
    </div>
  );
}
