export const getUrlImagem = (codProduto) => {
  let url_empresa;
  switch (localStorage.empresa) {
    case '1':
      url_empresa = 'images';
      break;
    case '2':
      url_empresa = 'images_lyor';
      break;
    case '3':
      url_empresa = 'images_house36';
      break;
    default:
      url_empresa = 'images';
  }
  return `https://gruporojemac.dnsalias.com/candide/${url_empresa}/${codProduto}.jpg`;
};

export const getUrlImagemEmbalabem = (codProduto) => {
  let url_empresa;
  switch (localStorage.codigodaempresa) {
    case '1':
      url_empresa = 'images';
      break;
    case '2':
      url_empresa = 'images_lyor';
      break;
    case '3':
      url_empresa = 'images_house36';
      break;
    default:
      url_empresa = 'images';
  }
  return `https://gruporojemac.dnsalias.com/candide/${url_empresa}/embalagens/${codProduto}.jpg`;
};
// export const getUrlImagem = (codProduto) => {
//     let url_empresa;
//     switch(localStorage['empresa'])
//     {
//         case "1":
//             url_empresa = "rojemac";
//             break;
//         case "2":
//             url_empresa = "lyor";
//         break;
//         case "3":
//             url_empresa = "urban";
//             break;
//     }
//     return "http://rojemac.hospedagemdesites.ws/geradora/" + url_empresa + "/" + codProduto + ".jpg";
// }
export const foto = (event, codp) => {
  event.preventDefault();
  window.open(getUrlImagem(codp), 'foto', 'scrollbars=no,resizable=no,toolbar=no,location=no,directories=no,status=no,menubar=no,top=300,left=500,width=320,height=220');
};

export const toDataUrl = (url) => fetch(url)
  .then((response) => response.blob())
  .then((blob) => URL.createObjectURL(blob));
