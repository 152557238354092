import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { validateToken } from "../Utils/Cookie";
import TabelaCaracteristicas from "./TabelaCaracteristicas";
import { useEffect } from "react";
import { useState } from "react";

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  rootDialog: {
    width: '50%'
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: 'space-between',
    display: 'flex'
  },
}))(MuiDialogActions);

export default function ModalCaracteristicas({
  caracteristicas,
  handleSnackbar,
  history,
}) {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false)
  const [caracteristicasParse, setCaracteristicasParse] = React.useState([])
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile( window.screen.width < 768)
  },[])

  const handleClickOpen = () => {
    const empresa = localStorage.getItem("codigodaempresa")
    if (!validateToken()) {
      localStorage.clear();
      localStorage.setItem("codigodaempresa", empresa);
      history.push("/login?logout=true")
    }
    if (!caracteristicas) {
      handleSnackbar({ message: 'Sem dados para exibição', variant: 'warning', duration: 2000 })
      setOpen(false)
    } else {
      setOpen(true);
    }
  }
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <a href={'#'} onClick={handleClickOpen}>Aplicações</a>
      : {caracteristicas.length}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xl"
        fullWidth
        className={isMobile ? 'dialog-caracteristica-mobile' : 'dialog-caracteristica'}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Consulta de Aplicações
        </DialogTitle>
        <DialogContent dividers>
          <TabelaCaracteristicas caracteristicas={caracteristicas} loader={loader} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
