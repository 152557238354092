import React from "react";
import { format, parseISO } from "date-fns";

import { Container } from "./styles";

export function BarraOffline({ dataAtualizacaoProduto }) {
  if (dataAtualizacaoProduto) {
    return (
      <Container>
        <p>
          Você está visualizando o produto no modo offline, pois os webservices
          Senior atualmente estão indisponíveis.
        </p>
        <p>
          A última atualização deste produto foi em:{" "}
          <span>{format(parseISO(dataAtualizacaoProduto), "dd/MM/yyyy HH:mm:ss")}</span>
        </p>
      </Container>
    );
  }

  return <></>;
}
