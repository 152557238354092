import axios from 'axios';

const { REACT_APP_API_NARWAL_TEMPORARIA } = process.env;

const getContainerProcessService = async (product, company) => {
  const request = (
    await axios.get(
      `${REACT_APP_API_NARWAL_TEMPORARIA}/lista-container/${product}/${company}`
    )
  )?.data;

  return request;
};


export { getContainerProcessService };
