import React from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TabelaReservados from "./TabelaReservados";
import Loading from "./Loading";
import { validateToken } from "../Utils/Cookie";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalReservados({
  codPro,
  titulo,
  total,
  offline,
  history,
  permissions,
  handleSnackbar
}) {
  const [open, setOpen] = React.useState(false);
  const [pedidos, setPedidos] = React.useState([])
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = async () => {
    const empresa = localStorage.getItem("codigodaempresa")
    if (!validateToken()) {
      localStorage.clear();
      localStorage.setItem("codigodaempresa", empresa);
      history.push("/login?logout=true");
    }
    if(permissions && permissions.length > 0) {
      const isRca = permissions.some(permission => permission.displayName === 'Espelho - RCA')
      if (isRca){
        handleSnackbar({ message: 'Acesso bloqueado', variant: 'error', duration: 2000 })
        return
      }
    }

    try {
      setLoading(true)
      const endpoint_api = process.env.REACT_APP_API;
      const { data } = await axios.get(`${endpoint_api}/data-carteira-pedido`, {
        params: {
          codEmp: empresa,
          codPro: codPro
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setPedidos(data.carteiraPedidos)
    } catch (error) {
      handleSnackbar({ message: 'Erro ao carregar informações', variant: 'error', duration: 2000 })
      setOpen(false)
    } finally {
      setLoading(false)
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <a href={'#'} onClick={handleClickOpen}>
        {titulo || "Campo não localizado."}
      </a>
      : {total}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="xl"
      >
        {offline && (
          <Typography
            style={{
              backgroundColor: "#222",
              color: "#FFF",
              padding: 20,
              width: "100%",
              textAlign: "center",
            }}
          >
            Você está visualizando uma consulta offline.
          </Typography>
        )}
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Consulta {titulo || "Campo não localizado."}
        </DialogTitle>
        <DialogContent dividers>
          <TabelaReservados pedidos={pedidos} loading={loading} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
