import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TabelaContainer from "./TabelaContainer";
import Loading from "./Loading";
import { validateToken } from "../Utils/Cookie";
import { parseNumberFormat } from "../Utils/Format";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalContainer(props) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { totalContainer, rowsContainer } = props;

  const handleClickOpen = () => {
    const { permissions } = props;
    if (!validateToken()) {
      const empresa = localStorage.getItem("codigodaempresa");
      localStorage.clear();
      localStorage.setItem("codigodaempresa", empresa);
      props.history.push("/login?logout=true");
    }
    if (permissions && permissions.length > 0) {
      const isRca = permissions.some(
        (permission) => permission.displayName === "Espelho - RCA"
      );
      if (isRca) {
        props.handleSnackbar({message: 'Acesso bloqueado', variant: 'error', duration: 2000})
        return;
      }
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div>
      <a href={"#"} onClick={handleClickOpen}>
        Container
      </a>
      : {parseNumberFormat(totalContainer)}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="xl"
      >
        {props.offline && (
          <Typography
            style={{
              backgroundColor: "#222",
              color: "#FFF",
              padding: 20,
              width: "100%",
              textAlign: "center",
            }}
          >
            Você está visualizando uma consulta offline.
          </Typography>
        )}
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Consulta Entradas de Container
        </DialogTitle>
        <DialogContent dividers>
          {loading ? <Loading /> : ""}
          <>
            <TabelaContainer
              codPro={props.codPro}
              entradasContainer={rowsContainer}
              setLoading={setLoading}
              permissions={props.permissions}
            />
          </>
        </DialogContent>
        <DialogActions>
          <p style={{ width: "100%" }}>
            {`Total de Entradas: ${parseNumberFormat(totalContainer)}`}
          </p>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
