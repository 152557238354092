export const validateToken = () => {
  const token = localStorage.getItem('token')
  const expires = localStorage.getItem('expiresToken')
  const schema = localStorage.getItem('schema')

  return token && schema && expires && new Date(expires) > new Date()
}

export const parseParamToObject = (search) => {
  if(search) {
    const params = search.substring(1).split('&')
    let obj = {}

    for(const param of params) {
      const paramObj = param.split('=')
      obj[paramObj[0]] = decodeURI(paramObj[1])
    }

    return obj
  }
  return {}
}

export const parseJwt = () => {
  const tokenSchema = localStorage.getItem('schema')
  if (tokenSchema) {
    return JSON.parse(Buffer.from(tokenSchema.split('.')[1], 'base64').toString())
  }
  return {}
}
