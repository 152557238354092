export const numberToMoney = (currency, value) => (value ? `${currency} ${value.toFixed(2).toString().replace('.', ',')}` : '');

export const formatReais = (value) => numberToMoney('R$', value);

export const formatPercent = (value) => {
  try {
    const parsed = parseFloat(value);
    return `${parsed.toFixed(2).toString().replace('.', ',')}%`;
  } catch (err) {
    return '0,00%';
  }
};

export const dateToString = (dateString) => {
  if (dateString) {
    try {
      const date = new Date(dateString);
      const dia = date.getDate() > 9 ? date.getDate().toString() : `0${date.getDate().toString()}`;
      const mes = date.getMonth() > 8 ? (date.getMonth() + 1).toString() : `0${(date.getMonth() + 1).toString()}`;
      const ano = date.getFullYear();
      return `${dia}/${mes}/${ano}`;
    } catch (err) {
      return '';
    }
  }
  return '';
};

export const parseNumberFormat = (value, qtd_digits = 0) => {
  if (isNaN(parseFloat(value))) {
    return '0';
  }

  return parseFloat(value).toLocaleString('pt-BR', {
    minimumFractionDigits: qtd_digits,
    maximumFractionDigits: qtd_digits,
  });
};


export const parseCompany = (codEmp) => {
  switch (Number(codEmp)) {
    case 1:
      return 'Wolff';
    case 2:
      return 'Lyor';
    case 3:
      return 'Urban';
    default:
      return '';
  }
};
