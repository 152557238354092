import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getContainerProcessService } from '../services/getContainerProcessService';
import { parseNumberFormat } from '../Utils/Format';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 13,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
}));

const dateToDefault = (dateString) => {
  const dateParts = dateString.split('/');
  const formattedDate = new Date(
    `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
  );
  return formattedDate;
};

export default function TabelaReservados(props) {
  const classes = useStyles();
  const [propRows, setPropRows] = useState([]);
  const [enableManufacturer, setEnableManufacturer] = useState(false)
  const { entradasContainer: rows, setLoading } = props;

  useEffect(() => {
    const newArr = [];
    (async () => {
      const enableManufacturerTemp = props.permissions && props.permissions.length > 0
        ? props.permissions.some(permission =>
          permission.displayName === 'Espelho - Diretoria'
          || permission.displayName === 'Espelho - Importação'
        )
        : false

      const codEmp = await localStorage.getItem('codigodaempresa');
      setEnableManufacturer(enableManufacturerTemp)
      setLoading(true)
      const containers = await getContainerProcessService(props.codPro, codEmp);
      containers.forEach(container => {
        const newObject = {
          data: container.data,
          quantidade: container.QtdeEmb,
          processo: container.NumeroProcesso,
          sistema: 'NARWAL',
          local: container.Porto,
          RefPc: container.RefPc,
          cd: container.cd,
          dataentregacd: container.dataentregacd,
          ...(enableManufacturerTemp && { fabricante: container.fabricante })
        };
        newArr.push(newObject);
      })
      // newArr.sort((a, b) => dateToDefault(b.data) - dateToDefault(a.data));
      const containersInDateOrder = newArr
        .concat(rows)
        .sort((a, b) => dateToDefault(b.data) - dateToDefault(a.data));
      setPropRows(containersInDateOrder);
      setLoading(false)
    })().catch((err) => {
      console.error(err);
      setLoading(false)
    });
  }, [props.codPro, rows]);

 
  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label='customized table'>
        <TableHead>
          <TableRow>
            <StyledTableCell align='center' width='50px'>
              Data da NF
            </StyledTableCell>
            <StyledTableCell align='center' width='80px'>
              Qtde. Entrada
            </StyledTableCell>
            <StyledTableCell align='center' width='80px'>
              Processo
            </StyledTableCell>
            <StyledTableCell align='center' width='80px'>
              Pedido
            </StyledTableCell>
            {enableManufacturer && (<StyledTableCell align='center' width='80px'>
              Fabricante
            </StyledTableCell>)}
            <StyledTableCell align='center' width='80px'>
              Sistema
            </StyledTableCell>
            <StyledTableCell align='center' width='80px'>
              Porto
            </StyledTableCell>
            <StyledTableCell align='center' width='80px'>
              CD
            </StyledTableCell>
            <StyledTableCell align='center' width='80px'>
              Data Entrega CD
            </StyledTableCell>
          </TableRow>
        </TableHead>
        {/* Se conseguir fazer requisição na Narwal para atualizar a coluna "Pedido" */}
        {propRows?.length &&
          propRows.map((row) => (
            <TableBody>
              <StyledTableRow key={row.codCli}>
                <StyledTableCell align='center'>{row.data}</StyledTableCell>
                <StyledTableCell align='center'>
                  {parseNumberFormat(row.quantidade)}
                </StyledTableCell>
                <StyledTableCell align='center'>{row.processo}</StyledTableCell>
                <StyledTableCell align='center'>
                  {row.RefPc || 'Sem processo'}
                </StyledTableCell>
                {enableManufacturer && (<StyledTableCell align='center'>
                  {row.fabricante || ''}
                </StyledTableCell>)}
                <StyledTableCell align='center'>{row.sistema}</StyledTableCell>
                <StyledTableCell align='center'>
                  {row.local}
                </StyledTableCell>
                <StyledTableCell align='center'>
                  {row.cd || '-'}
                </StyledTableCell>
                <StyledTableCell align='center'>
                  {row.dataentregacd}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          ))}
      </Table>
    </Paper>
  );
}
