import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TabelaProduzindo from './TabelaProduzindo';
import Loading from './Loading';
import { validateToken } from '../Utils/Cookie'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalProduzindo(props) {
  const [open, setOpen] = React.useState(false);
  const [pedidos, setPedidos] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [enableManufacturer, setEnableManufacturer] = React.useState(false)
  const { codPro, codEmp } = props;

  const handleClickOpen = () => {
    const { permissions } = props
    if(!validateToken()) {
      const empresa = localStorage.getItem("codigodaempresa")
      localStorage.clear();
      localStorage.setItem("codigodaempresa", empresa);
      props.history.push("/login?logout=true")
    }

    if(permissions && permissions.length > 0) {
      const isRca = permissions.some(permission => permission.displayName === 'Espelho - RCA')
      if (isRca){
        props.handleSnackbar({ message: 'Acesso bloqueado', variant: 'error', duration: 2000 })
        return
      }
    }

    const enableManufacturerTemp = props.permissions && props.permissions.length > 0
      ? props.permissions.some(permission =>
        permission.displayName === 'Espelho - Diretoria'
        || permission.displayName === 'Espelho - Importação'
        // || permission.displayName === 'TI - Desenvolvimento'
      )
      : false

    setOpen(true);
    setPedidos([]);
    setLoading(true);
    setEnableManufacturer(enableManufacturerTemp)
    fetch(`${process.env.REACT_APP_API_NARWAL_TEMPORARIA}/lista-produzindo/${codPro}/${codEmp}`, {
      headers: { method: 'GET', 'Content-Type': 'application/json' },
    }).then((res) => res.json().then((json) => {
      setLoading(false);
      setPedidos(json.map(pedido => {
        if(!enableManufacturer){
          delete pedido.fornecedor
        }
        return pedido
      }));
    }));
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <a href={'#'} onClick={handleClickOpen}>
         Produzindo Total
      </a>
      :
      {' '}
      {props.qtd}
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="xl">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Consulta Produzindo
        </DialogTitle>
        <DialogContent dividers>
          {loading && <Loading />}
          <TabelaProduzindo pedidos={pedidos} enableManufacturer={enableManufacturer} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
