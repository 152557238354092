import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

const isSmallScreen = window.screen.width < 1000
const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 200,
    hide: true,
  },
  {
    field: "desCte",
    headerName: "Descrição Característica",
    width: isSmallScreen ? 250 : 400,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "desCcp",
    headerName: "Descrição Componente",
    width: isSmallScreen ? 250 : 400,
    ...(!isSmallScreen && {
      flex: 1,
    }),
    headerAlign: "center",
    align: "center",
  },
];


export default function TabelaCaracteristicas({ caracteristicas, loader }) {
  return (
    <div style={{ height: isSmallScreen ? '100%' : 392, width: "100%" }}>
      <DataGrid
        loading={loader}
        rows={caracteristicas}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
        getRowId={(row) => row.codCte}
      />
    </div>
  );
}
