import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { parseNumberFormat } from '../Utils/Format';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 13,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

// function createData(codCli, nome, nroPed, dtPed, qtde, valor, status, obs) {
//   return {
//     codCli, nome, nroPed, dtPed, qtde, valor, status, obs,
//   };
// }

// const rows = [
//   createData('C00105', 'A PREFERIDA COMERCIA', '131872', '16-10-2019', 12, 863.93, 'Analise Cadastral', 'Desconto 20% + 10% + 5% + 5% ja incluso no pedido / Transportadora: Lastro/ tabela 23 apos conferencia do -Icliente/ITENS 3793 --47,26548 62,00 ,2684- 72,00 -valor aut por Neusa'),
//   createData('C00105', 'ROJEMAC IMPORTACAO', '131875', '16-10-2019', 252, 10863.93, 'Analise Cadastral', 'Desconto 20% + 10% + 5% + 5% ja incluso no pedido'),
// ];


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
}));

export default function TabelaReservados(props) {
  const classes = useStyles();
  const { pedidos: rows } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" width="50px">Codcli</StyledTableCell>
            <StyledTableCell align="left" width="200px">Nome</StyledTableCell>
            <StyledTableCell align="center" width="80px">Nroped</StyledTableCell>
            <StyledTableCell align="center" width="80px">DtPed</StyledTableCell>
            <StyledTableCell align="center" width="80px">NroPk</StyledTableCell>
            <StyledTableCell align="center" width="100px">DtPk</StyledTableCell>
            <StyledTableCell align="center" width="100px">QtdePk</StyledTableCell>
            <StyledTableCell align="center" width="100px">QtdePed</StyledTableCell>
            <StyledTableCell align="left" width="550px">Obs</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.codCli}>
              <StyledTableCell align="left">{row.codCli}</StyledTableCell>
              <StyledTableCell align="center">{row.nome}</StyledTableCell>
              <StyledTableCell align="center">{row.nroPed}</StyledTableCell>
              <StyledTableCell align="center">{row.dtPed}</StyledTableCell>
              <StyledTableCell align="center">{row.nroPk}</StyledTableCell>
              <StyledTableCell align="center">{row.dtPk}</StyledTableCell>
              <StyledTableCell align="center">{parseNumberFormat(row.qtdePk)}</StyledTableCell>
              <StyledTableCell align="center">{parseNumberFormat(row.qtdePed)}</StyledTableCell>
              <StyledTableCell align="center">{row.obs}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
