import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getContainerProcessService } from '../services/getContainerProcessService';
import { parseNumberFormat } from '../Utils/Format';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 13,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
}));

const dateToDefault = (dateString) => {
  const dateParts = dateString.split('/');
  const formattedDate = new Date(
    `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
  );
  return formattedDate;
};

export default function TabelaQualidade(props) {
  const classes = useStyles();
  const { rows } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label='customized table'>
        <TableHead>
          <TableRow>
            <StyledTableCell align='center' width='50px'>
              Data OR
            </StyledTableCell>
            <StyledTableCell align='center' width='80px'>
              OR
            </StyledTableCell>
            <StyledTableCell align='center' width='80px'>
              Qtde
            </StyledTableCell>
            <StyledTableCell align='center' width='80px'>
              NF
            </StyledTableCell>
            <StyledTableCell align='center' width='80px'>
              Status
            </StyledTableCell>
          </TableRow>
        </TableHead>
        {/* Se conseguir fazer requisição na Narwal para atualizar a coluna "Pedido" */}
        {rows?.length &&
          rows.map((row) => (
            <TableBody>
              <StyledTableRow key={row.id}>
                <StyledTableCell align='center'>
                  {row.datOr}
                </StyledTableCell>
                <StyledTableCell align='center'>
                  {parseNumberFormat(row.or)}
                </StyledTableCell>
                <StyledTableCell align='center'>
                  {parseNumberFormat(row.qtde)}
                </StyledTableCell>
                <StyledTableCell align='center'>
                  {parseNumberFormat(row.nf)}
                </StyledTableCell>
                <StyledTableCell align='center'>
                  {row.status}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          ))}
      </Table>
    </Paper>
  );
}
