import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { parseNumberFormat } from '../Utils/Format';
import { FaCircle } from "react-icons/fa";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 13,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
}));

export default function TabelaProduzindo(props) {
  const classes = useStyles();
  const rows = props.pedidos;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" width="64px">Pedido</StyledTableCell>
            <StyledTableCell align="center" width="64px">BID</StyledTableCell>
            <StyledTableCell align="center" width="64px">Produto</StyledTableCell>
            {props.enableManufacturer && (
              <>
                <StyledTableCell align="center" width="64px">Fabricante</StyledTableCell>
                <StyledTableCell align="center" width="64px">Data confirmação da P.I</StyledTableCell>
              </>
            )}
            <StyledTableCell align="center" width="64px">Data da Compra</StyledTableCell>
            <StyledTableCell align="center" width="64px">Data Prev Próx.Embarque</StyledTableCell>
            <StyledTableCell align="center" width="64px">Quantidade Produzindo</StyledTableCell>
            {props.enableManufacturer && (
              <>
                <StyledTableCell align="center" width="64px">Inspeção Agendada</StyledTableCell>
                <StyledTableCell align="center" width="64px">Inspeção Aprovada</StyledTableCell>
                <StyledTableCell align="center" width="64px">Status Inspeção</StyledTableCell>
                <StyledTableCell align="center" width="64px">Booking Navio</StyledTableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.processoNumero}>
              <StyledTableCell align="center">{row.pedidoNumero}</StyledTableCell>
              <StyledTableCell align="center">{row.bid}</StyledTableCell>
              <StyledTableCell align="center">{row.produtoChave}</StyledTableCell>
              {props.enableManufacturer && (
                <>
                  <StyledTableCell align="center">{row.fabricante}</StyledTableCell>
                  <StyledTableCell align="center">{row.dataConfPi ? row.dataConfPi : row.dataCriacao}</StyledTableCell>
                </>
              )}
              <StyledTableCell align="center">{row.dataCriacao}</StyledTableCell>
              <StyledTableCell align="center">{row.dataPrevisaoChegada}</StyledTableCell>
              <StyledTableCell align="center">{parseNumberFormat(row.quantidadeProduzindo)}</StyledTableCell>
              {props.enableManufacturer && (
                <>
                  <StyledTableCell align="center"><FaCircle style={{ color: row.dataInspecao === "VERDE" ? "green" : "red", fontSize: '1.2em' }} /></StyledTableCell>
                  <StyledTableCell align="center"><FaCircle style={{ color: row.dataInspecaoAprovada === "VERDE" ? "green" : "red", fontSize: '1.2em' }} /></StyledTableCell>
                  <StyledTableCell align="center">{row.statusInspecao === "NENHUM" || row.statusInspecao === null ? '' : row.statusInspecao}</StyledTableCell>
                  <StyledTableCell align="center"><FaCircle style={{ color: row.bookingNavio === "VERDE" ? "green" : "red", fontSize: '1.2em' }} /></StyledTableCell>
                </>
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
