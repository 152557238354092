import React, { useEffect } from 'react';
import axios from "axios";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TabelaBloqueados from './TabelaBloqueados';
import { validateToken } from "../Utils/Cookie";
import { parseNumberFormat } from '../Utils/Format';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalBloqueados(props) {
  const [open, setOpen] = React.useState(false);
  const [pedidos, setPedidos] = React.useState([]);
  const [offline, setOffline] = React.useState(false);

  const handleClickOpen = async () => {
    const { permissions, codPro, handleSnackbar, rows } = props
    const empresa = localStorage.getItem("codigodaempresa")
    if (!validateToken()) {
      localStorage.clear();
      localStorage.setItem("codigodaempresa", empresa);
      props.history.push("/login?logout=true")
    }

    if (permissions && permissions.length > 0) {
      const isRca = permissions.some(permission => permission.displayName === 'Espelho - RCA')
      if (isRca) {
        props.handleSnackbar({ message: 'Acesso bloqueado', variant: 'error', duration: 2000 })
        return
      }
    }

    try {
      // setLoading(true)
      const endpoint_api = process.env.REACT_APP_API;
      const numPeds = rows.map(pedido => pedido.numPed)
      const { data } = await axios.get(`${endpoint_api}/data-valores-bloqueado`, {
        params: {
          codEmp: empresa,
          codPro: codPro,
          numPeds
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const pedidosReservados = rows.map(pedido => {
        const reservado = data.valoresBloqueados?.find(
          (reservado) => Number(reservado?.numPed) === Number(pedido?.numPed)
        );
        return {
          ...pedido,
          vlrLinha: reservado?.vlrLinha || 0,
          status: reservado?.status
        }
      })
      console.log(pedidosReservados, 'pedidos reservados')
      setPedidos(pedidosReservados);
    } catch (error) {
      handleSnackbar({ message: 'Erro ao carregar informações', variant: 'error', duration: 2000 })
      setOpen(false)
    } finally {
      // setLoading(false)
    }

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <a href={'#'} onClick={handleClickOpen}>
        {props.titulo || 'Produto sem título no banco'}
      </a>
      :
      {' '}
      {parseNumberFormat(props.totReserva)}
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="xl">
        {offline && (
          <Typography
            style={{
              backgroundColor: '#222',
              color: '#FFF',
              padding: 20,
              width: '100%',
              textAlign: 'center'
            }}>
            Você está visualizando uma consulta offline.
          </Typography>
        )}
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Consulta {props.titulo || 'Produto sem título no banco'}
        </DialogTitle>
        <DialogContent dividers>
          <TabelaBloqueados pedidos={pedidos} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
