import React, { Component } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';


export default class Loading extends Component {
  render() {
    return (
      <Grid
        container
        justify="center"
        alignContent="center"
        style={{
          position: 'fixed', height: '100%', width: '100%', zIndex: '999', top: 0, left: 0, background: 'rgba(255, 255, 255, 0.7)', overflowY: 'hidden',
        }}
      >
        <CircularProgress variant="indeterminate" size="75px" />
      </Grid>
    );
  }
}
