import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@material-ui/core'
import { useEffect } from 'react'

export function EstruturaCompleta({
  estruturaCompleta,
  goToProduct,
  openImage
}) {
  const [estrutura, setEstrutura] = React.useState([])
  const empresa = localStorage.getItem('codigodaempresa')

  useEffect(() => {
    setEstrutura(estruturaCompleta)
  }, [estruturaCompleta])

  if (
      typeof estrutura === 'undefined' ||
      estrutura === null ||
      !estrutura.length ||
      (estrutura.length && estrutura[0] === null)
    ) {
    return <Typography style={{ fontWeight: 'bold' }}>Não existe estrutura para este item.</Typography>
  }

  return (
    <>
      <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>Estrutura</Typography>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

        <TableContainer component={Paper} style={{ maxHeight: 200, width: '100%' }}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell align="left">#</TableCell>
                <TableCell align="right">Produto</TableCell>
                <TableCell align="right">Disp. Fat. Imediato</TableCell>
                <TableCell align="right">Relação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {estrutura.map((row) => (
                <TableRow key={row.name}>
                  <TableCell align="left" style={{ padding: 4 }}>
                    <img
                      src={empresa === '1'
                        ? `http://gruporojemac.dnsalias.com/candide/images/${row.produto}.jpg`
                        : `http://gruporojemac.dnsalias.com/candide/images_lyor/${row.produto}.jpg`
                      }
                      alt={row.produto}
                      style={{ width: 42, height: 42, cursor: 'pointer' }}
                      onClick={(e) => openImage(e, empresa === '1'
                        ? `http://gruporojemac.dnsalias.com/candide/images/${row.produto}.jpg`
                        : `http://gruporojemac.dnsalias.com/candide/images_lyor/${row.produto}.jpg`
                      )}
                    />
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer', padding: 4 }}
                    onClick={() => goToProduct(row.produto)}
                  >
                    {row.produto}
                  </TableCell>
                  {/* Lógica para exibição de estoque unkit alterada conforme chamado 412795 */}
                  <TableCell align="right" style={{padding: 4 }}>
                    {row.estoque}
                  </TableCell>
                  <TableCell align="right" style={{ padding: 4 }}>{row.quantidade}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
