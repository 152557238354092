import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

const getColumns = (permissions) => {
  const columns = []
  const totalColumns = 7
  const accessProvider = permissions?.some(
    permission => permission.displayName === 'Espelho - Diretoria'
  ) || false


  for(let i = 0; i < totalColumns; i++) {
    switch (i) {
      case 0:
        columns.push({ 
        field: "id", 
        headerName: "#", 
        flex: 1, 
        minWidth: 50,
        flex: 1, 
        hide: true 
      })
        break
      case 1:
        columns.push({
          field: "BidNumero",
          headerName: "Bid Número",
          type: "string",
          minWidth: 150,
          headerAlign: "center",
          align: "center",
          flex: 1,
        })
        break
      case 2:
        columns.push({
          field: "DataBid",
          headerName: "Data Bid",
          type: "date",
          minWidth: 120,
          headerAlign: "center",
          align: "center",
          flex: 1,
        })
        break
      case 3:
        if(!accessProvider){
          break
        }
        columns.push(    {
          field: "Fabricante",
          headerName: "Fabricantes",
          type: "string",
          minWidth: 200,
          headerAlign: "center",
          align: "center",
          hide: true
        })
        break
      case 4:
        columns.push({
          field: "Quantidade",
          headerName: "Quantidade",
          type: "number",
          minWidth: 100,
          headerAlign: "center",
          align: "center",
          flex: 1,
        })
        break
        case 5:
          columns.push({
            field: "Comprador",
            headerName: "Comprador",
            type: "number",
            minWidth: 150,
            headerAlign: "center",
            align: "center",
            flex: 1,
          })
          break
      case 6:
        columns.push({
          field: "Status",
          headerName: "Status",
          type: "string", 
          minWidth: 150,
          headerAlign: "center",
          align: "center",
          flex: 1,
        })
        break
      default:
        continue
    }
  }

  return columns
}


export default function TabelaBid({ pedidos, permissions }) {
  const [columns, setColumns] = React.useState([])

  React.useEffect(() => {
    const columnsTemp = getColumns(permissions)
    console.log(columnsTemp, 'columns')
    setColumns(columnsTemp)
  }, [permissions])

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={pedidos}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
      />
    </div>
  );
}
