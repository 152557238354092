import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { validateToken } from "../Utils/Cookie";
import TabelaFabricando from "./TabelaFabricando";

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  rootDialog: {
    width: '50%'
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: 'space-between',
    display: 'flex'
  },
}))(MuiDialogActions);

export default function ModalFabricando({
  history,
  totFab,
  fabricando,
  permissions,
  handleSnackbar
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if(!validateToken()) {
      const empresa = localStorage.getItem("codigodaempresa")
      localStorage.clear();
      localStorage.setItem("codigodaempresa", empresa);
      history.push("/login?logout=true")
    }

    if(permissions && permissions.length > 0) {
      const isRca = permissions.some(permission => permission.displayName === 'Espelho - RCA')
      if (isRca){
        handleSnackbar({message: 'Acesso bloqueado', variant: 'error', duration: 2000})
        return
      }
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <a href={'#'} onClick={handleClickOpen}>
      Fabricando nacional
      </a>
      : {totFab}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xl"
        fullWidth
        className={'dialog-fabricante'}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Consulta Fabricando
        </DialogTitle>
        <DialogContent dividers>
          <TabelaFabricando fabricando={fabricando} />
        </DialogContent>
        <DialogActions>
          <span>{`Total fabricando: ${totFab}`}</span>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
