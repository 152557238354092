import React, { useCallback, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
// /material/TextField
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { useHistory } from "react-router-dom";

export const SearchProductByName = ({
  codEmpresa,
  produto,
  fillProduto,
  setCodigoBusca,
  selectedProduct,
  setSelectedProduct

}) => {
  const isDesktop = window.screen.width > 940;
  const history = useHistory();
  const [products, setProducts] = useState([]);
  // const [selectedProduct, setSelectedProduct] = useState("");
  const [opened, setOpened] = useState(false);
  const [codEmp, setCodEmp] = useState("");
  const [descricaoBusca, setDescricaoBusca] = useState('')

  const fetchProduct = useCallback(async () => {
    if (!!descricaoBusca.length && descricaoBusca.split(" ").length <= 5) {
      let filteredArray = (
        await axios.post(`${process.env.REACT_APP_API}/buscaProdutoNome`, {
          empresa: codEmp,
          produto: descricaoBusca.split(" "),
        })
      ).data;
      if (filteredArray.length) {
        filteredArray = filteredArray
          .map(({ codEmp, codPro, desPro }) => ({
            codEmp,
            codPro,
            desPro: `${codPro} - ${desPro}`,
          }))
          .sort((a, b) => a.codPro - b.codPro);
      }

      setProducts(filteredArray);
    }
  }, [codEmp, descricaoBusca]);

  useEffect(() => {
    codEmpresa && setCodEmp(parseInt(codEmpresa));
  }, [codEmpresa, produto]);

  useEffect(() => {
    if (descricaoBusca.length > 1) {
      const timeOutId = setTimeout(() => {
        fetchProduct();
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [fetchProduct, descricaoBusca.length]);

  useEffect(() => {
    if (!descricaoBusca.length) setOpened(false);
  }, [descricaoBusca.length]);

  return (
    <Autocomplete
      getOptionLabel={(option) => option?.desPro || ''}
      scroll
      filterOptions={(options, state) => options}
      options={products}
      value={selectedProduct}
      style={{
        display: "flex",
        width: isDesktop ? "75%" : '100%',
        [isDesktop ? 'marginLeft': '']: '10px',
        [isDesktop ? 'paddingTop': '']: '8px',
      }}
      onInputChange={(event, value) => {
        setOpened(true);
        setDescricaoBusca(value);
        if (!descricaoBusca.length) setOpened(false);
      }}
      onChange={(event, value) => {
        setOpened(false);
        setSelectedProduct(value);
        if (value && value.codPro) {
          history.push(`/produto/${value.codPro}`);
          setCodigoBusca(value.codPro);
          fillProduto(value.codPro, codEmp);
        }
      }}
      noOptionsText="Nenhum produto encontrado"
      open={opened}
      renderInput={(params) => (
        <TextField {...params} label="Digite o nome do produto" />
      )}
    />
  );
};
